export default {
    computed: {
        full_features() {
            return [
                {
                    // 0
                    img_path: require('@/assets/graphics/landing/pictures/features/saas_01.png'),
                    img_alt: this.$t('af_saas_alt'),
                    front_text: this.$t('af_saas_front'),
                    back_title: this.$t('af_saas_title'),
                    back_text: this.$t('af_saas_back'),
                    analytics_event: "saas"
                },
                {
                    // 1
                    img_path: require('@/assets/graphics/landing/pictures/features/multilanguage_01.png'),
                    img_alt: this.$t('af_multilanguage_alt'),
                    front_text: this.$t('af_multilanguage_front'),
                    back_title: this.$t('af_multilanguage_title'),
                    back_text: this.$t('af_multilanguage_back'),
                    analytics_event: "language"
                },
                {
                    // 2
                    img_path: require('@/assets/graphics/landing/pictures/features/crossplatform_01.png'),
                    img_alt: this.$t('af_crossplatform_alt'),
                    front_text: this.$t('af_crossplatform_front'),
                    back_title: this.$t('af_crossplatform_title'),
                    back_text: this.$t('af_crossplatform_back'),
                    analytics_event: "crossplatform"
                },
                {
                    // 3
                    img_path: require('@/assets/graphics/landing/pictures/features/versionhistory_01.png'),
                    img_alt: this.$t('af_versionhistory_alt'),
                    front_text: this.$t('af_versionhistory_front'),
                    back_title: this.$t('af_versionhistory_title'),
                    back_text: this.$t('af_versionhistory_back'),
                    analytics_event: "versionhistory"
                },
                {
                    // 4
                    img_path: require('@/assets/graphics/landing/pictures/features/geolocation_01.png'),
                    img_alt: this.$t('af_geolocation_alt'),
                    front_text: this.$t('af_geolocation_front'),
                    back_title: this.$t('af_geolocation_title'),
                    back_text: this.$t('af_geolocation_back'),
                    analytics_event: "geolocation"
                },
                {
                    // 5
                    img_path: require('@/assets/graphics/landing/pictures/features/qrcode_01.png'),
                    img_alt: this.$t('af_qrcodes_alt'),
                    front_text: this.$t('af_qrcodes_front'),
                    back_title: this.$t('af_qrcodes_title'),
                    back_text: this.$t('af_qrcodes_back'),
                    analytics_event: "qrcodes"
                },
                {
                    // 6
                    img_path: require('@/assets/graphics/landing/pictures/features/customization_01.png'),
                    img_alt: this.$t('af_customization_alt'),
                    front_text: this.$t('af_customization_front'),
                    back_title: this.$t('af_customization_title'),
                    back_text: this.$t('af_customization_back'),
                    analytics_event: "customization"
                },
                {
                    // 7
                    img_path: require('@/assets/graphics/landing/pictures/features/installable_01.png'),
                    img_alt: this.$t('af_installable_alt'),
                    front_text: this.$t('af_installable_front'),
                    back_title: this.$t('af_installable_title'),
                    back_text: this.$t('af_installable_back'),
                    analytics_event: "installable"
                },
                {
                    // 8
                    img_path: require('@/assets/graphics/landing/pictures/features/printpdf_01.png'),
                    img_alt: this.$t('af_printpdf_alt'),
                    front_text: this.$t('af_printpdf_front'),
                    back_title: this.$t('af_printpdf_title'),
                    back_text: this.$t('af_printpdf_back'),
                    analytics_event: "printpdf"
                },
                {
                    // 9
                    img_path: require('@/assets/graphics/landing/pictures/features/worldwide_accessible_01.png'),
                    img_alt: this.$t('af_wwwaccessible_alt'),
                    front_text: this.$t('af_wwwaccessible_front'),
                    back_title: this.$t('af_wwwaccessible_title'),
                    back_text: this.$t('af_wwwaccessible_back'),
                    analytics_event: "wwwaccessible"
                },
                {
                    // 10
                    img_path: require('@/assets/graphics/landing/pictures/features/autoupdate_01.png'),
                    img_alt: this.$t('af_autoupdate_alt'),
                    front_text: this.$t('af_autoupdate_front'),
                    back_title: this.$t('af_autoupdate_title'),
                    back_text: this.$t('af_autoupdate_back'),
                    analytics_event: "autoupdate"
                },
                {
                    // 11
                    img_path: require('@/assets/graphics/landing/pictures/features/notifications_01.png'),
                    img_alt: this.$t('af_notification_alt'),
                    front_text: this.$t('af_notification_front'),
                    back_title: this.$t('af_notification_title'),
                    back_text: this.$t('af_notification_back'),
                    analytics_event: "notification"
                },
                {
                    // 12
                    img_path: require('@/assets/graphics/landing/pictures/features/sharing_01.png'),
                    img_alt: this.$t('af_sharing_alt'),
                    front_text: this.$t('af_sharing_front'),
                    back_title: this.$t('af_sharing_title'),
                    back_text: this.$t('af_sharing_back'),
                    analytics_event: "sharing"
                },
                // 
                {
                    // 13
                    img_path: require('@/assets/graphics/landing/pictures/features/price_01.png'),
                    img_alt: this.$t('af_price_alt'),
                    front_text: this.$t('af_price_front'),
                    back_title: this.$t('af_price_title'),
                    back_text: this.$t('af_price_back'),
                    analytics_event: "price"
                },
                {
                    // 14
                    img_path: require('@/assets/graphics/landing/pictures/features/signs_01.png'),
                    img_alt: this.$t('af_signs_alt'),
                    front_text: this.$t('af_signs_front'),
                    back_title: this.$t('af_signs_title'),
                    back_text: this.$t('af_signs_back'),
                    analytics_event: "signs"
                },
                {
                    // 15
                    img_path: require('@/assets/graphics/landing/pictures/features/messages_01.png'),
                    img_alt: this.$t('af_messages_alt'),
                    front_text: this.$t('af_messages_front'),
                    back_title: this.$t('af_messages_title'),
                    back_text: this.$t('af_messages_back'),
                    analytics_event: "messages"
                },
                {
                    // 16
                    img_path: require('@/assets/graphics/landing/pictures/features/touch_01.png'),
                    img_alt: this.$t('af_touch_alt'),
                    front_text: this.$t('af_touch_front'),
                    back_title: this.$t('af_touch_title'),
                    back_text: this.$t('af_touch_back'),
                    analytics_event: "touch"
                },
                {
                    // 17
                    img_path: require('@/assets/graphics/landing/pictures/features/sync_01.png'),
                    img_alt: this.$t('af_sync_alt'),
                    front_text: this.$t('af_sync_front'),
                    back_title: this.$t('af_sync_title'),
                    back_text: this.$t('af_sync_back'),
                    analytics_event: "sync"
                },
            ]
        }

    }
}
